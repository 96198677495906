module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-theme-ui@0.17.2_@emotion+react@11.14.0_react@18.3.1__@mdx-js+react@2.3.0_react@_mz3x2khqmcjri4ynohxnccio2m/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18.3_sw3osw4wxdausyrwmjffgtepcy/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mâtcha Designs","short_name":"matcha","start_url":"/","background_color":"#B67D20","theme_color":"#B67D20","display":"minimal-ui","icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2ea6b3d68975eccb850a4dde0968d98b"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-nprogress@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18._lkb4u4jvbm2rc6yno4zlqmmu2m/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#B67D20","showSpinner":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18.3._ouo2vpttbrymetujnjiecjyrmu/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__react-dom@1_b2qp7xrzfnfgyzrsh4ajdaabw4/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-166068383-1"],"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18.3.1_react@18.3.1__react@18.3.1__cf6yj22apunihfobvnap5rfdga/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
